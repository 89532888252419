<template>
  <div>
    <BModal
      id="modal-redeem"
      ref="modal-redeem"
      hide-footer
      hide-header
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <ValidationObserver
        #default="{ invalid }"
        ref="form"
      >
        <BRow class="justify-center my-1 flex flex-column items-center gap-1">
          <BCol class="flex justify-center mb-1">
            <div class="text-[#333333] font-semibold text-2xl">
              Konfirmasi Penukaran
            </div>
          </BCol>
          <BCol cols="8">
            <Voucher
              :kompoints="kompoints"
              :voucher="voucher"
            />
          </BCol>
          <BCol>
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="font-medium text-base">Kartu Tujuan</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Kartu Tujuan"
                rules="required"
              >
                <VSelect
                  v-model="cardId"
                  placeholder="Pilih kartu"
                  label="card_name"
                  :options="optionsCards"
                  :reduce="option => option.value"
                  :loading="isLoading"
                >
                  <template #no-options>
                    Tidak ada kartu
                  </template>
                  <template #option="{ card_name }">
                    <div class="flex items center gap-2">
                      <img
                        class="w-6"
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
                        alt="komcard"
                      >
                      <span class="text-black">{{ card_name }}
                      </span>
                    </div>
                  </template>
                </VSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol class="text-base">
            <div class="flex items-center justify-between font-medium">
              <div class="text-black">
                Saldo Kompoints
              </div>
              <div class="text-[#D19D04]">
                {{ IDRWithoutLbl(kompoints.balance) }}
              </div>
            </div>
            <div class="flex items-center justify-between font-medium">
              <div class="text-black">
                Voucher Iklan
              </div>
              <div class="text-black">
                {{ IDRWithoutLbl(voucher.amount) }}
              </div>
            </div>
            <hr class="border-[#C2C2C2]">
            <div class="flex items-center justify-between">
              <div class="text-black font-semibold text-lg">
                Sisa saldo kompoints
              </div>
              <div class="text-[#D19D04] font-medium">
                {{ IDRWithoutLbl(balanceDifference) }}
              </div>
            </div>
          </BCol>
          <BCol class="flex items-center justify-between text-lg" />
        </BRow>
        <BRow class="mb-1">
          <BCol cols="6">
            <BButton
              variant="outline-primary"
              block
              @click="
                $refs['modal-redeem'].hide()
                cardId = null
              "
            >
              Batal
            </BButton>
          </BCol>
          <BCol cols="6">
            <BButton
              :disabled="invalid"
              :variant="invalid ? 'secondary' : 'primary'"
              type="submit"
              block
              @click.prevent="handleRedeem"
            >
              Redeem Sekarang
            </BButton>
          </BCol>
        </BRow>
      </ValidationObserver>
    </BModal>

    <BModal
      id="modal-confirmation"
      ref="modal-confirmation"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md text-center">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChangePin"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleBackToRedeem"
          >
            Kembali
          </BButton>
          <BButton
            :variant="pin.length < 6 || isLoadingRedeem ? 'secondary' : 'primary'"
            :disabled="pin.length < 6 || isLoadingRedeem"
            type="submit"
            @click.prevent="onSubmit"
          >
            <BSpinner
              v-if="isLoadingRedeem"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <BModal
      id="modal-success"
      ref="modal-success"
      hide-footer
      hide-header
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <BRow class="justify-center my-1 flex flex-column gap-3">
        <BCol class="d-flex justify-content-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
            alt="Komerce"
          >
        </BCol>
        <BCol class="text-black text-2xl text-center font-medium">
          Berhasil Redeem Voucher
        </BCol>
        <BCol class="text-black text-lg text-center">
          Kamu berhasil redeem voucher sebesar {{ IDR(voucher.amount) }}. Sekarang kamu bisa menikmati voucher yang sudah kamu redeem agar bayar iklan lebih murah
        </BCol>
      </BRow>
      <BRow class="mb-1">
        <BCol cols="6">
          <BButton
            variant="outline-primary"
            block
            @click="handleCloseModal"
          >
            Kembali
          </BButton>
        </BCol>
        <BCol cols="6">
          <BButton
            type="submit"
            block
            variant="primary"
            @click="$router.push('/komcards')"
          >
            Lihat mutasi
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import { required } from '@validations'
import { newAxiosIns } from '@/libs/axios'
import { IDRWithoutLbl, IDR } from '@/libs/currency'
import CodeInput from 'vue-verification-code-input'
import Voucher from './Voucher.vue'

export default {
  components: {
    Voucher,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    CodeInput,
  },
  props: {
    kompoints: {
      type: Object,
      default: () => ({}),
    },
    voucher: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      cardId: null,
      optionsCards: [],
      isLoading: false,
      isLoadingRedeem: false,
      isVisibility: true,
      visibilityPin: 'number',
      pin: '',
      IDRWithoutLbl,
      IDR,
      errorMessage: null,
    }
  },
  computed: {
    balanceDifference() {
      return this.kompoints.balance - this.voucher.kompoint_fee
    },
  },
  mounted() {
    this.getListCard()
  },
  methods: {
    async getListCard() {
      this.isLoading = true
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res
          const newData = data.data.map(item => ({
            ...item,
            value: item.id,
            card_name: `${item.label} *${this.splitCardNumber(item.card_number)}`,
          }))
          this.optionsCards = newData
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Gagal mendapatkan daftar kartu' })
        })
    },
    async onSubmit() {
      const url = '/komcards/api/v1/voucher/redeem'
      this.isLoadingRedeem = true
      const payload = {
        pin: this.pin,
        redeem_to: this.cardId,
        voucher_id: this.voucher.id,
      }
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.isLoadingRedeem = false
          this.$refs['modal-success'].show()
          this.$refs['modal-confirmation'].hide()
          this.pin = ''
          this.cardId = null
        })
        .catch(err => {
          const { message } = err.response.data.meta
          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang kamu masukkan salah'
          }

          if (message === 'card not found') {
            this.errorMessage = 'Kartu tidak ditemukan'
          }

          if (message === 'pending transaction found, cannot proceed') {
            this.$toast_error({ message: 'Transaksi sedang diproses, tidak bisa melanjutkan transaksi lain' })
          }

          this.isLoadingRedeem = false
          this.pin = ''
        })
    },
    handleRedeem() {
      this.$refs['modal-confirmation'].show()
      this.$refs['modal-redeem'].hide()
    },
    handleBackToRedeem() {
      this.$refs['modal-confirmation'].hide()
      this.$refs['modal-redeem'].show()
      this.pin = ''
      this.errorMessage = null
    },
    handleCloseModal() {
      this.$refs['modal-success'].hide()
      this.$emit('getVoucherList')
      this.$emit('getKompoints')
      this.cardId = null
      this.pin = ''
    },
    visiblePin() {
      this.isVisibility = !this.isVisibility
      this.visibilityPin = this.isVisibility ? 'number' : 'text'
    },
    handleChangePin(value) {
      this.pin = value
    },
    splitCardNumber(number) {
      return number.split(' ').pop()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
